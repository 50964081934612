.App-logo {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  max-width: 100%;
  width: 280px;
  height: auto;
}

.App-header {
  background-color: #bbcaeb;
  padding: 5px;
  text-align: center;
}

.App-content {
  max-width: 780px;
  margin: 0 auto;
  padding: 8px;
}

.App-Footer {
  max-width: 780px;
  margin: 25px auto;
  color: #555;
  font-size: 12px;
  padding: 6px;
  text-align: center;
  background-color: #fff;
  opacity: 50%;
}

.App-Footer a {
  color: rgb(66, 69, 253);
}

.App-intro {
  font-size: large;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

._appLinks_e6ad3 {
  display: block;
  margin: 1em;
  padding: 0;
  list-style: none;
}

._appLinks_e6ad3 > li {
  display: block;
  white-space: nowrap;
}

._appLinks_e6ad3 > li + li {
  margin-top: 8px;
}

.bFold {
  margin-top: 120px;
  width: 100%;
}

@media only screen and (min-width: 780px) {
  ._appLinks_e6ad3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  ._appLinks_e6ad3 > li + li {
    margin-top: 0;
    margin-left: 8px;
  }
  ._appLinks_e6ad3 > li:after {
    content: "\2022";
    margin-left: 8px;
  }
  ._appLinks_e6ad3 > li:last-child:after {
    content: "";
    margin: 0;
  }
}
